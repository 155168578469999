import React, { Component } from 'react'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'

// SVG LISTO! SUPERDINERO
import SuperDinero from '../../../assets/svg/afi/landing-broker/superdinero.svg';
import Listo from '../../../assets/svg/afi/landing-broker/listo.svg';
import Mybambu from '../../../assets/svg/afi/landing-broker/mybambu.png';
// import AfiLogo from '../../../assets/svg/afi-logo.svg';

import {    
    CardElevation,
    ButtonDegrade,    
} from '@findep/mf-landings-core'
import { withThemeProps } from '@findep/microfronts-core'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'


import { Typography, FormHelperText, Checkbox, FormGroup, Grid,  Container, FormControlLabel} from "@material-ui/core"
import AvisosApp from '../../elements/AvisosApp.compilable';
import { navigate } from '../../../helpers/queryNavigate';

import { CataloguesService } from '../../../services/LandingV4/catalogues';



const styleTextMessage = css`
    margin: 1rem;
    font-size: 22px;
    font-weight: 100;        
    line-height: 1.43;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);          
`
const buttonCss = css`
    display: flex;
    justify-content: flex-center;    
`

const marginGrid = css `
    margin: 2em;
`

const gridCenter = css `
    text-align: center;
`

const centerElement = css `
    margin-left: 20px;
`

const catalogues = new CataloguesService();


class LandingBroker extends Component {       
    
    constructor(props) {
        super(props);
        this.state = {
            terminos: false,
            data: {
                firstName:'',
                broker: ''
            }
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(prop, data) {        
        this.setState({
            [prop]: data.value
        });        
        // console.log(this.state);
    }

    async componentDidMount() {        
        let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        let id = flujo?.creditApplicationId  
        this.setState({ creditApplicationId: id })

        // console.log("Credit Application: ", id);

        // catalogues.getBrokers('6080ab589046040001c5b0c8')
        // catalogues.getBrokers('6081a8b99046040001c5b0db')
        catalogues.getBrokers(id)
      .then((response) => {    
        flujo = {
            ...flujo,
            covered: response.data.coverageResult
        }           
        sessionStorage.setItem('flujo', JSON.stringify(flujo))  
        this.setState({
            data: response.data          
        })
      })
      .catch(error => {
        console.error(error)
      })
        
        
    }

    async submitStepTwo() {
        let navigateUrl = this.props.pageContext?.next?.path;                        
        navigate(navigateUrl);        
    }

    // Funci1ón para el firsName que mantiene la primer letra mayusculas y las demas en minuscula
    capitalizeName() {
        const firstName = this.state.data.firstName;                
        if(firstName !== '') {
            return firstName[0] + firstName.slice(1).toLowerCase();            
        }
    }

    // Función para pintar el logo correspondiente, convierte en mayuscula el broker
    logo(broker) {
        const upperBroker = broker.toUpperCase();
        // console.log("Broker", upperBroker);
        
            return upperBroker !== ('' || null) &&
                (this.state.data.broker === 'superdinero' || this.state.data.broker === 'listo' || this.state.data.broker === 'bambu') &&                                   
                <Grid item css={gridCenter}>
                    <img src={this.state.data.broker === 'superdinero'? SuperDinero : this.state.data.broker === 'listo' ? Listo : Mybambu}                                        
                        alt="logotipo super dinero"
                        width="50%"                                                  
                    />                      
                </Grid>          
            
    }

    
    
    render() {


        const {
            welcome,
            nextButtonText,
            helloText,
            terms = (company) => (
                <>
                    Acepto <AvisosApp terminos company={company}>los términos y condiciones</AvisosApp>
                </>
            ),
            avisos = (company) => (
                <>
                    y el <AvisosApp avisos company={company}>aviso de privacidad</AvisosApp>
                </>
            )
        } = this.props


        return (
            <Container justify="center" maxWidth="sm">
                <br/><br/>
                <Grid>
                    <CardElevation>
                        <Grid container justify="center">                                                                                     
                            <Grid item>
                                {
                                    this.logo(this.state.data.broker)                                  
                                }
                                { this.state.data.firstName === null 
                                ? <Typography variant="h4" align="center">
                                    ¡{helloText}!
                                  </Typography>                                                                        
                                : <Typography variant="h4" align="center">                                                                                                            
                                    ¡{helloText} <strong>{this.state.data.firstName && this.capitalizeName()}</strong>!                                    
                                  </Typography>                                                                                      
                                }                                                                                                
                                <Typography align="center" css={styleTextMessage}>
                                    {welcome}
                                </Typography> 
                            </Grid>                                                                        
                        </Grid>                                                                        

                        <Grid container justify="center">                                                                                     
                            <Grid item css={marginGrid}>                                                                                                                 
                                <FormGroup css={centerElement}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.terminos}
                                                onChange={(_event, component) => this.handleChange('terminos', { value: _event.target.checked })}
                                                id="tus-datos-terminos-checkbox"
                                                name="tus-datos-terminos-checkbox"                                                
                                                color="primary"                                
                                            />
                                        }
                                        label={
                                            <FormHelperText>
                                                <Typography variant="body2">{terms(this.props.pageContext.company)}</Typography>
                                                <Typography variant="body2">{avisos(this.props.pageContext.company)}</Typography>
                                            </FormHelperText>
                                        }                                        
                                    >
                                    </FormControlLabel>
                        
                                </FormGroup>

                            </Grid>
                        </Grid>

                        <Grid container justify="center">
                            <Grid item css={marginGrid}> 
                                <div css={buttonCss} >
                                <ButtonDegrade
                                    id="tus-datos-continuar-solicitud-button"
                                    icon={ArrowForwardIcon}
                                    textButton={nextButtonText}
                                    onClick={() => this.submitStepTwo()}
                                    disabled={!this.state.terminos}
                                />
                                </div>
                            </Grid>
                        </Grid>
                    </CardElevation>
                </Grid>
            </Container>
        )
    }
}

LandingBroker.defaultProps = {
    welcome: (
        <>te damos la bienvenida a
            <br />
            <strong>Apoyo Financiero</strong>
        </>
    ),
    nextButtonText: 'CONTINUAR',
    helloText: 'Hola',
}

export default withThemeProps(LandingBroker, 'VDNPLandingBroker')